<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="hidden-sm-and-down">
      <div
        class="section1 pb-16"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="ml-16">
            <v-col class="pt-16">
              <div class="title-text-small mt-16">
                Get a <span class="primary--text">term life</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="ml-12 pt-8"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/termlife-icon.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/canadiantermrc/survey"
                large
              >
                <span class="px-4">Term Life Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-16">
          <v-col
            class="d-flex justify-center"
          >
            <div
              class="travel-text pa-4"
            >
              <span class="product-text">OUR PRODUCTS</span> <br>
              Find insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pb-4">
          <v-col
            cols="12"
            sm="9"
            md="9"
            class="text-center"
          >
            <div class="product-subtext">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-sheet max-width="90%">
            <v-slide-group
              show-arrows
            >
              <v-slide-item
                v-for="(item, index) in productLinks"
                :key="index"
              >
                <v-card
                  flat
                  @click="goTo(item.route)"
                >
                  <v-img
                    :src="item.img"
                    class="mx-auto zoom"
                    height="180"
                    width="180"
                  />
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
      </div>
    </div>
    <!-------------------------------TABLET-------------------------------->
    <div v-show="tabletDevice">
      <div
        class="section1-tablet pb-16"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="ml-16">
            <v-col class="pt-16">
              <div class="title-text-tablet">
                Get a <span class="primary--text">term life</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="ml-12 pt-8"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/parachute-icon.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/canadiantermrc/survey"
                large
              >
                <span class="px-4">Term Life Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-6">
          <v-col
            class="d-flex justify-center text-center"
          >
            <div class="product-text-tablet text-center">
              OUR PRODUCTS
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            class="d-flex justify-center title-tablet"
          >
            Find insurance coverage you need
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="9"
            class="subtext-tablet"
          >
            Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-sheet max-width="90%">
            <v-slide-group
              show-arrows
            >
              <v-slide-item
                v-for="(item, index) in productLinks"
                :key="index"
              >
                <v-card
                  flat
                  @click="goTo(item.route)"
                >
                  <v-img
                    :src="item.img"
                    class="mx-auto zoom"
                    height="180"
                    width="180"
                  />
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
      </div>
    </div>
    <!-------------------------------------------------------MOBILE------------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="text-h2 title-text-mobile pt-10">
                Get a <span class="primary--text">term life</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row class="d-flex justify-center pt-4">
            <v-col
              cols="7"
              class="d-flex align-center justify-center"
            >
              <v-btn
                block
                class="primary white--text font-weight-bold"
                large
                to="/canadianterm/survey"
              >
                <span>Term Life Quote</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col
              cols="7"
              class="d-flex align-center justify-center pt-1 mt-0"
            >
              <v-btn
                class="white learn font-weight-bold"
                large
                block
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-4 d-flex-justify-center">
          <v-col
            class="d-flex justify-center"
          >
            <div class="text-center">
              <span class="product-text-mobile">OUR PRODUCTS</span>
              <br>
              <span class="coverage-text-mobile">Find insurance <br> coverage you need</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <div class="product-subtext-mobile">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center"
        >
          <v-col
            cols="12"
          >
            <v-slide-group
              multiple
              show-arrows
            >
              <v-card
                v-for="(item, index) in productLinks"
                :key="index"
                flat
                @click="goTo(item.route)"
              >
                <v-img
                  :src="item.img"
                  height="140"
                  width="140"
                />
              </v-card>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        productLinks: [
          { title: 'Auto', route: '/auto', img: '/img/product-chips/car.png' },
          { title: 'Homeowners', route: '/homeowners', img: '/img/product-chips/home.png' },
          { title: 'Pet', route: '/pet', img: '/img/product-chips/pet.png' },
          { title: 'Travel', route: '/travel/usa', img: '/img/product-chips/travel.png' },
          { title: 'Business', route: '/business', img: '/img/product-chips/business.png' },
          { title: 'Condo', route: '/condo', img: '/img/product-chips/condo.png' },
          { title: 'Landlord', route: '/landlord', img: '/img/product-chips/landlord.png' },
          { title: 'Renters', route: '/renters', img: '/img/product-chips/renters.png' },
        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    created () {
      this.$gtag.pageview('Dashboard')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.product-subtext-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 132.19%;
/* or 19px */

text-align: center;

/* Gray/600 */

color: #757575;
}
.coverage-text-mobile {
font-family: 'Roboto';
font-size: 32px;
font-weight: 400;
line-height: 38px;
letter-spacing: -0.005em;
text-align: center;
color: #757575;
}
.title-text-small {
  font-weight: 400;
  font-size: 55px;
  line-height: 125.19%;
  letter-spacing: -0.04em;
  color: #616161;
  }
.subtext-mobile {
  font-weight: 400;
font-size: 14px;
line-height: 132.19%;
text-align: center;
color: #757575;
}
.subtext-tablet {
font-size: 20px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
color: #757575;
}
.product-text-mobile {
font-size: 20px;
font-weight: 300;
line-height: 23px;
letter-spacing: -0.005em;
color: #757575;
}

.product-text-tablet {
font-size: 32px;
font-weight: 300;
line-height: 38px;
letter-spacing: -0.005em;
color: #757575;
}
.title-mobile {
  font-size: 32px;
font-weight: 400;
line-height: 38px;
letter-spacing: -0.005em;
color: #757575;
}

.title-tablet{
font-size: 50px;
font-weight: 400;
line-height: 59px;
letter-spacing: -0.005em;
color: #757575;
}
.auto-qt-mobile {
  max-width:  100px;
  object-fit: scale-down;
  margin-right: -2rem;
  z-index: 5;
}
.title-text-mobile {
    font-weight: 400;
font-size: 32px;
line-height: 125.19%;
color: #616161;
text-align: center;
letter-spacing: -0.04em;
  }
.title-text-tablet {
font-size: 55px;
font-weight: 400;
line-height: 69px;
letter-spacing: -0.04em;
text-align: left;
color: #616161;
  }

  .product-text {
    font-size: 36px;
    font-weight: 300;
  }

  .product-subtext {
    font-size: 24px;
    font-weight: 400;
    line-height: 31.73px;
    color: #757575;
  }

  .section1{
  position: relative;
  background: url("/img/term-life-background.png") no-repeat;
  height: 700px;
  background-size: contain;
  background-color: white;
  background-position: right;
}

.section1-tablet{
  height: 1100px;
  background: url("/img/termlife-tablet.png") no-repeat;
  background-size: contain;
  background-color: white;
  background-position: center 20rem;
}

.section1-mobile{
  min-height: 800px;
  background: url("/img/termlife-mobile.png") no-repeat;
  background-size: contain;
  background-color: white;
  background-position: center 19rem;
}

.section2{
  background-color: #FFFFFF;
}

.large {
    height: 900px;
}
.travel-qt {
  max-width:  120px;
  object-fit: scale-down;
  margin-right: -4rem;
  z-index: 5;
}

.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
background-color: white;
}

.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}
</style>
